<template lang="pug">
  .sidebar__menu(:class="[{'sidebar__menu_active': isActive}]")
    component(:is="component" :to="to" @click="$emit('click')")
      v-tooltip(right content-class="sidebar-tooltip")
        template(v-slot:activator="{on}")
          .sidebar-menu(
            v-on="on"
            @click="$emit('click')"
            @mouseover="$emit('mouseover', $event)"
            @mouseleave="$emit('mouseleave', $event)"
          )
            .sidebar-menu__wrapper
              slot(name="icon")
        span {{ label }}
      slot
</template>

<script>
export default {
  name: 'SidebarMenuItem',

  props: {
    label: String,
    component: {
      type: String,
      default: 'router-link'
    },
    to: {
      type: null,
      default: ''
    },
    active: Boolean
  },

  computed: {
    isActive() {
      return (this.to.name === this.$route.name) || (this.active)
    }
  }
}
</script>

<style lang="scss">
.sidebar-tooltip {
  @include media("<=tablet") {
    display: none !important;
  }
}
</style>
